.card {
  border-radius: 5px;
  width: 100%;
  transition: all .3s;
  box-shadow: 0 4px 8px #0003;
}

@media only screen and (device-width >= 320px) and (device-width <= 355px) and (-webkit-device-pixel-ratio >= 2) and (orientation: portrait) {
  .card {
    width: 90%;
  }
}

.card:hover {
  box-shadow: 0 8px 16px #0003;
}

.card-url {
  color: #006494;
  text-decoration: none;
}

.card-header-url {
  color: #fff;
  text-decoration: underline;
}

.card-header-url:hover {
  color: #2a468e;
}

.card-text {
  color: #565e67;
}

.listing {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 2em;
  padding: 0;
  list-style: none;
  display: grid;
}

.listing .body {
  padding: 10px;
}

.list-group {
  max-height: 200px;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.legendbuttons {
  padding-top: 2em;
  padding-right: 1em;
}

.legenditem {
  padding-right: 1em;
}

.badge-collection {
  color: #fff;
  background-color: #c485e9;
}

.border-collection {
  border-color: #c485e9;
}

.badge-collab {
  color: #fff;
  background-color: #e9858a;
}

.badge-template {
  color: #fff;
  background-color: #e06b3c;
}

.border-collab {
  border-color: #e9858a;
}

#listCollContainerAllColl {
  max-height: 70vh;
}

.badge-warning {
  color: #fff;
}

#infocontainer {
  margin-top: 2em;
}

.hidden-first {
  visibility: hidden;
  animation: 5s forwards fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.play-control-card {
  cursor: pointer;
}
/*# sourceMappingURL=index.ec35b95e.css.map */
